import { Controller } from "@hotwired/stimulus"
import { createConsumer } from "@rails/actioncable"

// Events controller for handling event updates via ActionCable
//
// <div data-controller="events" 
//      data-events-timeline-id-value="123" 
//      data-events-display-mode-value="open"
//      data-events-live-preview-enabled-value="true">
//   <div id="events-container">
//     <!-- Event cards rendered by Rails -->
//   </div>
// </div>
export default class extends Controller {
  static targets = ["container", "bulkToolbar", "selectedCount"]
  static values = {
    timelineId: String,
    displayMode: { type: String, default: "open" },
    livePreviewEnabled: { type: Boolean, default: false },
    audioEnabled: { type: Boolean, default: false },
    viewMode: { type: String, default: "thumbnails" },
    selectionMode: { type: Boolean, default: false }
  }
  
  connect() {
    this.eventIds = new Set()
    this.selectedEvents = new Set()
    this.isDragging = false
    this.dragStartedOnCard = false
    
    this.setupActionCable()
    this.notificationSound = new Audio(window.pingSoundPath || '/assets/ping.mp3')
    
    // First set up event listeners before dispatching events
    this.setupEventListeners()
    
    // Update thumbnails directly instead of using dispatchLivePreviewToggle
    this.updateLivePreviewState(this.livePreviewEnabledValue)
    
    // Set up mouse drag selection listeners
    this.setupDragSelectionListeners()
    
    // Initial fetch of events - make sure to trigger it immediately
    this.fetchEvents()
    
    // Add loading indicator immediately
    this.showLoading();
    
    // If no events are visible after a short delay, retry the fetch
    setTimeout(() => {
      // Check if container has any children other than loading state
      let hasEvents = false;
      if (this.containerTarget) {
        // Look for real event elements, not just the loading indicators or empty state messages
        hasEvents = this.containerTarget.querySelectorAll(".xanthumb-card").length > 0;
      }
      
      if (!hasEvents) {
        console.log("No events found on initial load, retrying fetch...")
        this.fetchEvents()
      }
    }, 1000);
    
    // Final backup - check again after 3 seconds
    setTimeout(() => {
      // If we still don't have any events, try one more time
      let hasEvents = false;
      if (this.containerTarget) {
        hasEvents = this.containerTarget.querySelectorAll(".xanthumb-card").length > 0;
      }
      
      if (!hasEvents) {
        console.log("Still no events, final retry attempt...")
        this.fetchEvents()
      }
    }, 3000);
  }
  
  setupEventListeners() {
    // Live preview toggle
    this.boundHandleLivePreviewToggle = this.handleLivePreviewToggle.bind(this)
    document.addEventListener("events:livePreviewChanged", this.boundHandleLivePreviewToggle)
    
    // View mode changed (list vs thumbnails)
    this.boundHandleViewModeChanged = this.handleViewModeChanged.bind(this)
    document.addEventListener("events:viewModeChanged", this.boundHandleViewModeChanged)
    
    // Request to fetch events (from filter or view menu)
    this.boundHandleFetchEvents = this.handleFetchEvents.bind(this)
    document.addEventListener("events:fetchEvents", this.boundHandleFetchEvents)
    
    // Realtime updates toggle
    this.boundHandleRealtimeUpdatesChanged = this.handleRealtimeUpdatesChanged.bind(this)
    document.addEventListener("events:realtimeUpdatesChanged", this.boundHandleRealtimeUpdatesChanged)
    
    // Audio toggle
    this.boundHandleAudioToggle = this.handleAudioToggle.bind(this)
    document.addEventListener("events:audioToggle", this.boundHandleAudioToggle)
    
    // Scaling changed
    this.boundHandleScalingChanged = this.handleScalingChanged.bind(this)
    document.addEventListener("events:scalingChanged", this.boundHandleScalingChanged)
    
    // Grid size changed
    this.boundHandleGridSizeChanged = this.handleGridSizeChanged.bind(this)
    document.addEventListener("events:gridSizeChanged", this.boundHandleGridSizeChanged)
  }
  
  disconnect() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
    
    // Clean up event listeners
    document.removeEventListener("events:livePreviewChanged", this.boundHandleLivePreviewToggle)
    document.removeEventListener("events:viewModeChanged", this.boundHandleViewModeChanged)
    document.removeEventListener("events:fetchEvents", this.boundHandleFetchEvents)
    document.removeEventListener("events:realtimeUpdatesChanged", this.boundHandleRealtimeUpdatesChanged)
    document.removeEventListener("events:audioToggle", this.boundHandleAudioToggle)
    document.removeEventListener("events:scalingChanged", this.boundHandleScalingChanged)
    document.removeEventListener("events:gridSizeChanged", this.boundHandleGridSizeChanged)
    
    // Clean up drag selection listeners
    this.cleanupDragSelectionListeners()
  }
  
  // Drag Selection Methods
  
  setupDragSelectionListeners() {
    // Mouse events for the thumbnails container
    if (this.hasContainerTarget) {
      this.boundStartDragSelection = this.startDragSelection.bind(this)
      this.boundDragOver = this.dragOver.bind(this)
      this.boundEndDragSelection = this.endDragSelection.bind(this)
      
      this.containerTarget.addEventListener('mousedown', this.boundStartDragSelection)
      document.addEventListener('mousemove', this.boundDragOver)
      document.addEventListener('mouseup', this.boundEndDragSelection)
    }
  }
  
  cleanupDragSelectionListeners() {
    if (this.hasContainerTarget) {
      this.containerTarget.removeEventListener('mousedown', this.boundStartDragSelection)
      document.removeEventListener('mousemove', this.boundDragOver)
      document.removeEventListener('mouseup', this.boundEndDragSelection)
    }
  }
  
  startDragSelection(event) {
    // Only start drag selection if we're in selection mode and not clicking on a control element
    if (!this.selectionModeValue || 
        event.target.closest('a, button, input, .dropdown-toggle, .dropdown-menu, .checkbox-container')) {
      return
    }
    
    // Check if drag started on a card
    const card = event.target.closest('.xanthumb-card')
    this.dragStartedOnCard = !!card
    
    // Start drag selection
    this.isDragging = true
    
    // Store the starting position
    this.startX = event.clientX
    this.startY = event.clientY
    
    // If drag started on a card, handle its selection 
    if (this.dragStartedOnCard && card) {
      const eventId = card.dataset.eventId
      if (eventId) {
        // Toggle selection for this card
        const checkbox = card.querySelector('input[type="checkbox"]')
        if (checkbox) {
          this.initialDragSelection = !checkbox.checked
          checkbox.checked = this.initialDragSelection
          
          if (this.initialDragSelection) {
            this.selectedEvents.add(eventId)
            card.classList.add('selected')
          } else {
            this.selectedEvents.delete(eventId)
            card.classList.remove('selected')
          }
          
          this.updateSelectionUI()
        }
      }
    }
    
    // Prevent default to avoid text selection
    event.preventDefault()
  }
  
  dragOver(event) {
    // Only process if in selection mode and actually dragging
    if (!this.selectionModeValue || !this.isDragging) return
    
    // Find the element under the cursor
    const elementsUnderCursor = document.elementsFromPoint(event.clientX, event.clientY)
    const card = elementsUnderCursor.find(el => el.matches('.xanthumb-card'))
    
    // If we found a card and we're in thumbnail view
    if (card && this.viewModeValue === 'thumbnails') {
      const eventId = card.dataset.eventId
      if (eventId) {
        // Get checkbox
        const checkbox = card.querySelector('input[type="checkbox"]')
        if (checkbox) {
          // Set checkbox state to match initial selection (use initialDragSelection from mousedown)
          checkbox.checked = this.initialDragSelection
          
          // Add or remove from selection set
          if (this.initialDragSelection) {
            this.selectedEvents.add(eventId)
            card.classList.add('selected')
          } else {
            this.selectedEvents.delete(eventId)
            card.classList.remove('selected')
          }
        }
      }
    }
    
    // Similarly handle list rows if in list view
    if (this.viewModeValue === 'list') {
      const row = elementsUnderCursor.find(el => el.matches('.event-row'))
      if (row) {
        const eventId = row.dataset.eventId
        if (eventId) {
          const checkbox = row.querySelector('input[type="checkbox"]')
          if (checkbox) {
            checkbox.checked = this.initialDragSelection
            
            if (this.initialDragSelection) {
              this.selectedEvents.add(eventId)
              row.classList.add('selected')
            } else {
              this.selectedEvents.delete(eventId)
              row.classList.remove('selected')
            }
          }
        }
      }
    }
    
    // Update selection UI 
    this.updateSelectionUI()
  }
  
  endDragSelection(event) {
    if (this.isDragging) {
      this.isDragging = false
      
      // Update selection UI one final time
      this.updateSelectionUI()
    }
  }
  
  // Selection Mode Value Changed
  selectionModeValueChanged() {
    // Add or remove the selection-mode class to the main container
    const eventsContainer = document.querySelector('.events-container')
    if (eventsContainer) {
      eventsContainer.classList.toggle('selection-mode', this.selectionModeValue)
    }
    
    // If selection mode is turned off, clear the selection
    if (!this.selectionModeValue) {
      if (this.selectedEvents) {
        this.clearSelection()
      }
    }
    
    // Update UI for selection mode
    this.updateSelectionUI()
  }
  
  // Event Selection Methods
  
  toggleSelectAll(event) {
    const isChecked = event.target.checked
    const checkboxes = document.querySelectorAll('input[type="checkbox"][data-action*="events#toggleSelectEvent"]')
    
    if (!this.selectionModeValue) {
      this.selectionModeValue = true
    }
    
    checkboxes.forEach(checkbox => {
      checkbox.checked = isChecked
      const eventId = checkbox.dataset.eventId
      if (isChecked) {
        this.selectedEvents.add(eventId)
      } else {
        this.selectedEvents.delete(eventId)
      }
    })
    
    this.updateSelectionUI()
  }
  
  toggleSelectEvent(event) {
    // Enable selection mode if it's not already on
    if (!this.selectionModeValue) {
      this.selectionModeValue = true
    }
    
    const checkbox = event.target
    const eventId = checkbox.dataset.eventId
    
    if (checkbox.checked) {
      this.selectedEvents.add(eventId)
    } else {
      this.selectedEvents.delete(eventId)
    }
    
    // If the user deselected all events, keep selection mode on anyway
    this.updateSelectionUI()
  }
  
  // Toggle selection when clicking on the event card
  toggleCardSelection(event) {
    // Only process if we're in selection mode
    if (!this.selectionModeValue) return
    
    // Don't process if the user clicked on a link or button
    if (event.target.closest('a, button, input, .dropdown-toggle, .dropdown-menu')) {
      return
    }
    
    const card = event.currentTarget
    const eventId = card.dataset.eventId
    const checkbox = card.querySelector('input[type="checkbox"]')
    
    if (!eventId || !checkbox) return
    
    // Toggle checkbox state
    checkbox.checked = !checkbox.checked
    
    // Update selection set
    if (checkbox.checked) {
      this.selectedEvents.add(eventId)
      card.classList.add('selected')
    } else {
      this.selectedEvents.delete(eventId)
      card.classList.remove('selected')
    }
    
    this.updateSelectionUI()
  }
  
  updateSelectionUI() {
    if (!this.selectedEvents) return
    
    const hasSelection = this.selectedEvents.size > 0
    
    // Show/hide bulk operations toolbar based on selection
    if (this.hasBulkToolbarTarget) {
      this.bulkToolbarTarget.classList.toggle('d-none', !hasSelection)
      
      // Update count
      if (this.hasSelectedCountTarget) {
        this.selectedCountTarget.textContent = this.selectedEvents.size
      }
    }
    
    // For thumbnail view
    document.querySelectorAll('.xanthumb-card').forEach(card => {
      const eventId = card.dataset.eventId
      card.classList.toggle('selected', this.selectedEvents.has(eventId))
      
      // Update checkbox
      const checkbox = card.querySelector('input[type="checkbox"]')
      if (checkbox) {
        checkbox.checked = this.selectedEvents.has(eventId)
      }
    })
    
    // For list view
    document.querySelectorAll('.event-row').forEach(row => {
      const eventId = row.dataset.eventId
      row.classList.toggle('selected', this.selectedEvents.has(eventId))
      
      // Update checkbox
      const checkbox = row.querySelector('input[type="checkbox"]')
      if (checkbox) {
        checkbox.checked = this.selectedEvents.has(eventId)
      }
    })
  }
  
  // Clear the current selection
  clearSelection() {
    if (this.selectedEvents) {
      this.selectedEvents.clear()
      this.updateSelectionUI()
    }
  }
  
  // Bulk Operations
  
  // Helper method to execute bulk operations
  executeBulkOperation(operation, extraParams = {}) {
    if (this.selectedEvents.size === 0) return
    
    if (!confirm(`Are you sure you want to ${operation} the selected events?`)) {
      return
    }
    
    const eventIds = Array.from(this.selectedEvents)
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    
    // Show loading indicators
    this.showBulkOperationLoading(operation)
    
    // Build the request parameters
    const params = {
      operation: operation,
      event_ids: eventIds
    }
    
    // Add any extra parameters
    Object.assign(params, extraParams)
    
    // Make the request
    fetch(`/events/${operation}/bulk_operation`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
        'Accept': 'application/json'
      },
      body: JSON.stringify(params)
    })
    .then(response => {
      if (!response.ok) throw new Error(`${operation} operation failed`)
      return response.json()
    })
    .then(data => {
      // Handle success
      this.handleBulkOperationSuccess(operation, data)
    })
    .catch(error => {
      // Handle error
      console.error('Bulk operation error:', error)
      this.handleBulkOperationError(operation, error)
    })
    .finally(() => {
      // Remove loading indicators
      this.hideBulkOperationLoading(operation)
    })
  }
  
  // Bulk operation handlers
  bulkDelete(event) {
    this.executeBulkOperation('remove')
  }
  
  bulkFlag(event) {
    this.executeBulkOperation('flag')
  }
  
  bulkUnflag(event) {
    this.executeBulkOperation('unflag')
  }
  
  bulkClose(event) {
    this.executeBulkOperation('close')
  }
  
  bulkReopen(event) {
    this.executeBulkOperation('open')
  }
  
  bulkShare(event) {
    // Use the share modal
    if (typeof window.openShareEventsModal === 'function') {
      window.openShareEventsModal(Array.from(this.selectedEvents))
    } else {
      // Fallback if modal not available
      const emails = prompt('Enter email addresses separated by commas:')
      if (emails) {
        this.executeBulkOperation('share', { to: emails })
      }
    }
  }
  
  // Loading state handling
  showBulkOperationLoading(operation) {
    // Disable buttons
    document.querySelectorAll('#bulk-operations-toolbar .btn').forEach(btn => {
      btn.setAttribute('disabled', true)
    })
    
    // Add a loading spinner to the specific button
    const button = document.querySelector(`#bulk-operations-toolbar [data-action*="events#bulk${operation.charAt(0).toUpperCase() + operation.slice(1)}"]`)
    if (button) {
      button.classList.add('loading')
      const icon = button.querySelector('i')
      if (icon) {
        icon.className = 'fas fa-spinner fa-spin mr-2'
      }
    }
  }
  
  hideBulkOperationLoading(operation) {
    // Re-enable buttons
    document.querySelectorAll('#bulk-operations-toolbar .btn').forEach(btn => {
      btn.removeAttribute('disabled')
    })
    
    // Remove loading spinner
    const button = document.querySelector(`#bulk-operations-toolbar [data-action*="events#bulk${operation.charAt(0).toUpperCase() + operation.slice(1)}"]`)
    if (button) {
      button.classList.remove('loading')
      // Restore original icon
      const icon = button.querySelector('i')
      if (icon) {
        // Set appropriate icon based on operation
        let iconClass = 'fa-trash'
        switch (operation) {
          case 'flag': iconClass = 'fa-flag'; break
          case 'unflag': iconClass = 'far fa-flag'; break
          case 'close': iconClass = 'fa-folder'; break
          case 'open': iconClass = 'fa-folder-open'; break
          case 'share': iconClass = 'fa-share'; break
          case 'remove': 
          default: iconClass = 'fa-trash'
        }
        icon.className = `fas ${iconClass} mr-2`
      }
    }
  }
  
  handleBulkOperationSuccess(operation, data) {
    // Show success message
    const message = `Successfully ${this.getOperationPastTense(operation)} ${this.selectedEvents.size} events.`
    this.showNotification(message, 'success')
    
    // Special handling based on operation
    switch (operation) {
      case 'remove':
        // Remove the events from the DOM
        this.selectedEvents.forEach(eventId => {
          this.removeEventWithAnimation(eventId)
        })
        break
      case 'close':
        // If in "open" display mode, remove the events
        if (this.displayModeValue === 'open') {
          this.selectedEvents.forEach(eventId => {
            this.removeEventWithAnimation(eventId)
          })
        } else {
          // Otherwise refresh to show updated state
          this.fetchEvents()
        }
        break
      case 'open':
        // If in "closed" display mode, remove the events
        if (this.displayModeValue === 'closed') {
          this.selectedEvents.forEach(eventId => {
            this.removeEventWithAnimation(eventId)
          })
        } else {
          // Otherwise refresh to show updated state
          this.fetchEvents()
        }
        break
      default:
        // Refresh to show updated state
        this.fetchEvents()
    }
    
    // Clear selection
    this.clearSelection()
  }
  
  handleBulkOperationError(operation, error) {
    // Show error message
    const message = `Failed to ${operation} events: ${error.message}`
    this.showNotification(message, 'error')
  }
  
  // Helper for getting past tense of operations
  getOperationPastTense(operation) {
    switch (operation) {
      case 'remove': return 'deleted'
      case 'flag': return 'flagged'
      case 'unflag': return 'unflagged'
      case 'close': return 'closed'
      case 'open': return 'reopened'
      case 'share': return 'shared'
      default: return `${operation}d`
    }
  }
  
  // Show a notification
  showNotification(message, type = 'info') {
    // Check if toastr is available
    if (typeof toastr !== 'undefined') {
      toastr[type](message)
    } else {
      // Fallback to alert
      alert(message)
    }
  }
  
  // Event listener handlers
  
  handleViewModeChanged(event) {
    const { viewMode } = event.detail
    this.viewModeValue = viewMode
    
    // Switch container display based on view mode
    const thumbnailsContainer = document.getElementById('events-thumbnails-container')
    const listContainer = document.getElementById('events-list-container')
    
    if (thumbnailsContainer && listContainer) {
      thumbnailsContainer.classList.toggle('d-none', viewMode !== 'thumbnails')
      listContainer.classList.toggle('d-none', viewMode !== 'list')
    }
  }
  
  handleLivePreviewToggle(event) {
    const { enabled } = event.detail
    this.livePreviewEnabledValue = enabled
    
    // Broadcast to all thumbnails
    this.dispatchLivePreviewToggle(enabled)
  }
  
  handleRealtimeUpdatesChanged(event) {
    const { enabled } = event.detail
    
    if (!enabled && this.subscription) {
      // Temporarily disconnect from ActionCable
      this.subscription.unsubscribe()
      this.subscription = null
    } else if (enabled && !this.subscription) {
      // Reconnect to ActionCable
      this.setupActionCable()
    }
  }
  
  handleAudioToggle(event) {
    const { enabled } = event.detail
    this.audioEnabledValue = enabled
  }
  
  handleScalingChanged(event) {
    const { scaling } = event.detail
    // Apply scaling classes to all thumbnails
    document.querySelectorAll('.xanthumb-card').forEach(card => {
      card.classList.toggle('scaling-fit', scaling === 'fit')
      card.classList.toggle('scaling-fill', scaling === 'fill')
    })
  }
  
  handleGridSizeChanged(event) {
    const { gridSize } = event.detail
    const container = document.getElementById('events-thumbnails-container')
    
    if (container) {
      // Remove existing grid classes
      container.classList.remove('row-cols-1', 'row-cols-2', 'row-cols-3', 'row-cols-4', 'row-cols-5', 'row-cols-6')
      // Add the new grid class
      container.classList.add(`row-cols-${gridSize}`)
    }
  }
  
  handleFetchEvents(event) {
    const { isFilterSubmit } = event.detail || {}
    this.fetchEvents(isFilterSubmit)
  }
  
  // Set up ActionCable consumer and subscription
  setupActionCable() {
    if (!this.timelineIdValue) {
      console.warn("No timeline ID provided for events subscription")
      return
    }
    
    const consumer = createConsumer()
    this.subscription = consumer.subscriptions.create(
      { channel: "EventsChannel", id: this.timelineIdValue },
      {
        connected: this.cableConnected.bind(this),
        disconnected: this.cableDisconnected.bind(this),
        received: this.cableReceived.bind(this)
      }
    )
  }
  
  cableConnected() {
    console.log(`Connected to events stream for timeline ${this.timelineIdValue}`)
  }
  
  cableDisconnected() {
    console.log(`Disconnected from events stream for timeline ${this.timelineIdValue}`)
  }
  
  // Process event updates from ActionCable
  cableReceived(data) {
    console.log(`Received event update, operation: ${data.operation}`, data)
    
    // Get event ID
    const eventId = data.data?.id
    if (!eventId) return
    
    // Check if this is a duplicate event we're already processing
    if (this.eventIds.has(eventId)) {
      console.log(`Ignoring duplicate event ${eventId}`)
      return
    }
    
    // Track this event ID temporarily to avoid duplicates
    this.eventIds.add(eventId)
    setTimeout(() => {
      this.eventIds.delete(eventId)
    }, 2000)
    
    // Handle the event based on the operation type
    switch (data.operation) {
      case 'create':
        this.handleEventCreate(data)
        break
      case 'update':
        this.handleEventUpdate(data)
        break
      case 'complete':
        this.handleEventComplete(data)
        break
      case 'close':
        this.handleEventClose(data)
        break
      case 'reopen':
        this.handleEventReopen(data)
        break
      case 'delete':
        this.handleEventDelete(data)
        break
      default:
        this.handleLegacyEvent(data)
        break
    }
  }
  
  // Handler for toggling live preview
  handleLivePreviewToggle(event) {
    const { enabled } = event.detail
    // Only update the value, don't dispatch another event to avoid infinite loop
    this.livePreviewEnabledValue = enabled
    
    // NO BROADCAST - would cause infinite loop
    // this.dispatchLivePreviewToggle(enabled)
  }
  
  // Update live preview state directly (used during initialization)
  updateLivePreviewState(enabled) {
    // Find all the thumbnail elements with live-preview controller
    document.querySelectorAll('[data-controller~="live-preview"]').forEach(thumbnailElement => {
      const livePreviewController = this.application.getControllerForElementAndIdentifier(
        thumbnailElement, 'live-preview'
      )
      if (livePreviewController) {
        livePreviewController.enabledValue = enabled
        if (enabled && !livePreviewController.isComplete) {
          livePreviewController.startPreview()
        }
      }
    })
  }
  
  // Broadcast live preview state to all thumbnails (used for user actions)
  dispatchLivePreviewToggle(enabled) {
    document.dispatchEvent(
      new CustomEvent("events:livePreviewChanged", {
        detail: { enabled }
      })
    )
  }
  
  // CRUD operation handlers
  
  // Handle a new event being created
  handleEventCreate(data) {
    const eventData = data.data
    const shouldDisplay = this.shouldDisplayEvent(eventData)
    
    if (shouldDisplay) {
      this.fetchAndRenderEvent(eventData.id)
    }
  }
  
  // Handle an existing event being updated
  handleEventUpdate(data) {
    const eventData = data.data
    const eventElement = this.findEventElement(eventData.id)
    
    if (eventElement) {
      // Update the event if it's in the DOM
      this.fetchAndReplaceEvent(eventData.id)
    } else if (this.shouldDisplayEvent(eventData)) {
      // Add the event if it should now be displayed
      this.fetchAndRenderEvent(eventData.id)
    }
  }
  
  // Handle an event being marked as complete
  handleEventComplete(data) {
    const eventData = data.data
    const eventElement = this.findEventElement(eventData.id)
    
    if (eventElement) {
      // Mark the event as complete to stop live preview
      const thumbnailElement = eventElement.querySelector('[data-controller="live-preview"]')
      if (thumbnailElement) {
        const livePreviewController = this.application.getControllerForElementAndIdentifier(
          thumbnailElement, 'live-preview'
        )
        if (livePreviewController) {
          livePreviewController.complete()
        }
      }
      
      // Also fetch latest data to ensure it's current
      this.fetchAndReplaceEvent(eventData.id)
    }
  }
  
  // Handle an event being closed
  handleEventClose(data) {
    const eventData = data.data
    const eventElement = this.findEventElement(eventData.id)
    
    if (eventElement) {
      if (this.displayModeValue === 'open') {
        // Remove from open view with animation
        this.removeEventWithAnimation(eventData.id)
      } else {
        // Update in place for closed or everything view
        this.fetchAndReplaceEvent(eventData.id)
      }
    } else if (this.displayModeValue === 'closed' || this.displayModeValue === 'everything') {
      // Add to closed view
      this.fetchAndRenderEvent(eventData.id)
    }
  }
  
  // Handle an event being reopened
  handleEventReopen(data) {
    const eventData = data.data
    const eventElement = this.findEventElement(eventData.id)
    
    if (eventElement) {
      if (this.displayModeValue === 'closed') {
        // Remove from closed view with animation
        this.removeEventWithAnimation(eventData.id)
      } else {
        // Update in place for open or everything view
        this.fetchAndReplaceEvent(eventData.id)
      }
    } else if (this.displayModeValue === 'open' || this.displayModeValue === 'everything') {
      // Add to open view
      this.fetchAndRenderEvent(eventData.id)
    }
  }
  
  // Handle an event being deleted
  handleEventDelete(data) {
    const eventId = data.data.id
    this.removeEventWithAnimation(eventId)
  }
  
  // Handle legacy events (backward compatibility)
  handleLegacyEvent(data) {
    const eventData = data.data
    const eventId = eventData.id
    const eventElement = this.findEventElement(eventId)
    
    if (!eventElement && this.shouldDisplayEvent(eventData)) {
      // New event to add
      this.fetchAndRenderEvent(eventId)
    } else if (eventElement) {
      if (eventData.closed && this.displayModeValue === 'open') {
        // Closed event in open view - remove it
        this.removeEventWithAnimation(eventId)
      } else if (!eventData.closed && this.displayModeValue === 'closed') {
        // Reopened event in closed view - remove it
        this.removeEventWithAnimation(eventId)
      } else {
        // Update event
        this.fetchAndReplaceEvent(eventId)
      }
    }
  }
  
  // Helper functions
  
  // Determine if an event should be displayed in the current view
  shouldDisplayEvent(eventData) {
    const isClosed = eventData.closed === true
    
    if (this.displayModeValue === 'open' && !isClosed) {
      return true
    } else if (this.displayModeValue === 'closed' && isClosed) {
      return true
    } else if (this.displayModeValue === 'everything') {
      return true
    }
    
    return false
  }
  
  // Find an event element in the DOM
  findEventElement(eventId) {
    return document.getElementById(`event_${eventId}_${this.displayModeValue}`) ||
           document.getElementById(`event_${eventId}_${this.displayModeValue}_list`) ||
           document.querySelector(`[data-event-id="${eventId}"]`)
  }
  
  // Remove an event with fade-out animation
  removeEventWithAnimation(eventId) {
    const element = this.findEventElement(eventId)
    if (!element) return
    
    element.style.transition = 'opacity 0.3s ease-out'
    element.style.opacity = '0'
    
    setTimeout(() => {
      element.remove()
    }, 300)
  }
  
  // Fetch events from the server
  fetchEvents(isFilterSubmit = false) {
    // Clear selection if filter was changed
    if (isFilterSubmit) {
      this.selectedEvents.clear()
    }
    
    // Get the form data from the filter form
    const form = document.querySelector('#events_filter form')
    if (!form) {
      console.error("Events filter form not found")
      return
    }
    
    // Show loading state
    this.showLoading()
    
    // Create a FormData object
    const formData = new FormData(form)
    
    // Build the URL with parameters
    const formParams = new URLSearchParams(formData)
    const url = `${form.action}?${formParams.toString()}`
    
    // Fetch events with current filter
    fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'text/javascript',
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
    .then(response => {
      if (!response.ok) throw new Error('Network response was not ok')
      return response.text()
    })
    .then(html => {
      // The response is a JS that will update the page
      // We need to eval it to make it work
      if (html.trim().length > 0) {
        try {
          // Log what we're about to execute for debugging
          console.log('Executing JS response to update events')
          eval(html)
        } catch (e) {
          console.error('Error executing JS response:', e)
        }
      } else {
        console.warn('Empty response received when fetching events')
      }
      
      // Hide loading state
      this.hideLoading()
    })
    .catch(error => {
      console.error('Error fetching events:', error)
      this.hideLoading()
    })
  }
  
  // Show loading state
  showLoading() {
    // Get both containers
    const thumbnailsContainer = document.getElementById('events-thumbnails-container')
    const listContainer = document.getElementById('events-list-container')
    
    // Create a full-screen loading overlay if it doesn't exist
    if (!document.getElementById('events-loading-overlay')) {
      const overlay = document.createElement('div')
      overlay.id = 'events-loading-overlay'
      overlay.className = 'loading-overlay d-none'
      overlay.innerHTML = `
        <div class="spinner-container">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      `
      document.querySelector('.events-container').appendChild(overlay)
      
      // Add CSS for the overlay
      const style = document.createElement('style')
      style.textContent = `
        .loading-overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(255, 255, 255, 0.7);
          z-index: 1000;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .spinner-container {
          background-color: white;
          padding: 20px;
          border-radius: 5px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }
      `
      document.head.appendChild(style)
    }
    
    // Show the loading overlay
    const overlay = document.getElementById('events-loading-overlay')
    if (overlay) {
      overlay.classList.remove('d-none')
    }
    
    // Also add loading class to both containers
    if (thumbnailsContainer) {
      thumbnailsContainer.classList.add('loading')
    }
    
    if (listContainer) {
      listContainer.classList.add('loading')
    }
  }
  
  // Hide loading state
  hideLoading() {
    // Hide the loading overlay
    const overlay = document.getElementById('events-loading-overlay')
    if (overlay) {
      overlay.classList.add('d-none')
    }
    
    // Remove loading class from both containers
    const thumbnailsContainer = document.getElementById('events-thumbnails-container')
    const listContainer = document.getElementById('events-list-container')
    
    if (thumbnailsContainer) {
      thumbnailsContainer.classList.remove('loading')
    }
    
    if (listContainer) {
      listContainer.classList.remove('loading')
    }
  }
  
  // Fetch an event from the server and render it to the DOM
  fetchAndRenderEvent(eventId) {
    fetch(`/events/${eventId}/card?mode=${this.displayModeValue}`, {
      headers: {
        'Accept': 'text/html',
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
    .then(response => {
      if (!response.ok) throw new Error('Network response was not ok')
      return response.text()
    })
    .then(html => {
      // Check again in case the event was removed while we were fetching
      if (!this.findEventElement(eventId)) {
        // Add to the beginning of the container
        if (this.hasContainerTarget) {
          const tempDiv = document.createElement('div')
          tempDiv.innerHTML = html.trim()
          
          const newElement = tempDiv.firstChild
          if (newElement) {
            // Insert at the right position
            if (this.containerTarget.children.length > 0) {
              this.containerTarget.insertBefore(newElement, this.containerTarget.firstChild)
            } else {
              this.containerTarget.appendChild(newElement)
            }
            
            // Start with opacity 0 and fade in
            newElement.style.opacity = '0'
            newElement.style.transition = 'opacity 0.5s ease-in'
            
            setTimeout(() => {
              newElement.style.opacity = '1'
              
              // Start live preview if enabled and event is not complete
              if (this.livePreviewEnabledValue) {
                const thumbnailElement = newElement.querySelector('[data-controller="live-preview"]')
                if (thumbnailElement) {
                  const livePreviewController = this.application.getControllerForElementAndIdentifier(
                    thumbnailElement, 'live-preview'
                  )
                  if (livePreviewController) {
                    livePreviewController.startPreview()
                  }
                }
              }
            }, 50)
          }
        }
      }
    })
    .catch(error => {
      console.error('Error fetching event:', error)
    })
  }
  
  // Fetch an event and replace the existing element
  fetchAndReplaceEvent(eventId) {
    const existingElement = this.findEventElement(eventId)
    if (!existingElement) return
    
    fetch(`/events/${eventId}/card?mode=${this.displayModeValue}`, {
      headers: {
        'Accept': 'text/html',
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
    .then(response => {
      if (!response.ok) throw new Error('Network response was not ok')
      return response.text()
    })
    .then(html => {
      // Check if the element still exists
      const elementToReplace = this.findEventElement(eventId)
      if (elementToReplace) {
        // Keep track of the live preview state
        const oldThumbnailElement = elementToReplace.querySelector('[data-controller="live-preview"]')
        let isPreviewRunning = false
        
        if (oldThumbnailElement) {
          const oldController = this.application.getControllerForElementAndIdentifier(
            oldThumbnailElement, 'live-preview'
          )
          if (oldController) {
            isPreviewRunning = oldController.enabledValue && !oldController.isComplete
          }
        }
        
        // Replace the element with new HTML
        const tempDiv = document.createElement('div')
        tempDiv.innerHTML = html.trim()
        const newElement = tempDiv.firstChild
        
        if (newElement) {
          elementToReplace.replaceWith(newElement)
          
          // If live preview was running, restart it on the new element
          if (isPreviewRunning) {
            const newThumbnailElement = newElement.querySelector('[data-controller="live-preview"]')
            if (newThumbnailElement) {
              setTimeout(() => {
                const newController = this.application.getControllerForElementAndIdentifier(
                  newThumbnailElement, 'live-preview'
                )
                if (newController) {
                  newController.startPreview()
                }
              }, 50)
            }
          }
        }
      }
    })
    .catch(error => {
      console.error('Error updating event:', error)
    })
  }
}