import { Controller } from "@hotwired/stimulus"

// Handles live preview for event thumbnails
//
// <div data-controller="live-preview" 
//      data-live-preview-tetherbox-value="TB123456"
//      data-live-preview-path-value="/events/123/preview"
//      data-live-preview-enabled-value="true">
//   <img data-live-preview-target="image" src="...">
// </div>
export default class extends Controller {
  static targets = ["thumbnail", "image"]
  static values = { 
    tetherbox: String,
    path: String,
    eventId: String,
    complete: { type: Boolean, default: false },
    enabled: { type: Boolean, default: false },
    interval: { type: Number, default: 250 },
    longPause: { type: Number, default: 1000 }
  }
  
  connect() {
    this.isFetching = false
    // Use the complete value from data attribute
    this.isComplete = this.completeValue || this.element.classList.contains('completed')
    
    // Check if we have all necessary parameters before starting preview
    if (!this.pathValue) {
      // No preview path provided, mark as complete to avoid showing loading spinner
      this.isComplete = true
      return
    }
    
    // Don't start preview for completed events
    if (this.enabledValue && !this.isComplete) {
      this.startPreview()
    }
    
    // Listen for global live preview toggle event
    this.boundTogglePreview = this.handleTogglePreview.bind(this)
    document.addEventListener("events:livePreviewChanged", this.boundTogglePreview)
  }
  
  disconnect() {
    this.stopPreview()
    document.removeEventListener("events:livePreviewChanged", this.boundTogglePreview)
  }
  
  // Handle external toggle of live preview from the view_menu controller
  handleTogglePreview(event) {
    const { enabled } = event.detail
    
    // Update the enabled value
    this.enabledValue = enabled
    
    // If enabled and not complete, start the preview
    if (enabled && !this.isComplete && this.pathValue) {
      this.startPreview()
    } else {
      // Otherwise stop the preview and remove loading indicators
      this.stopPreview()
      this.element.classList.remove('loading')
    }
  }
  
  // Mark event as complete (stops preview)
  complete() {
    this.isComplete = true
    this.element.classList.add('completed')
    this.element.classList.remove('loading')
    this.stopPreview()
    this.reloadImage()
  }
  
  // Explicit method to start preview
  startPreview() {
    if (this.isComplete) return
    
    this.element.classList.add('loading')
    this.element.classList.remove('completed')
    this.enabledValue = true
    this.runImagePreview()
  }
  
  // Stop the preview
  stopPreview() {
    clearTimeout(this.previewTimer)
    this.isFetching = false
  }
  
  // Run image preview with auto-refresh
  runImagePreview() {
    if (this.isFetching || !this.enabledValue || this.isComplete) return
    
    this.isFetching = true
    let timer = this.intervalValue
    
    this.previewTimer = setTimeout(() => {
      const parentElement = this.element.closest('.xanthumb-card')
      
      // Check if element is off-screen
      if (parentElement && 
          parentElement.getBoundingClientRect().top + parentElement.offsetHeight < 0) {
        console.log('Pausing live preview - element off screen')
        timer = this.longPauseValue
        this.isFetching = false
        this.runImagePreview()
        return
      }
      
      // Create URL for the image
      const pathUrl = this.pathValue
      const timestamp = Date.now()
      const imageUrl = `${pathUrl}?t=${timestamp}`
      
      // Create new image
      const img = new Image()
      
      // Load image to data URL to avoid caching
      this.loadImageToDataURL(imageUrl, 
        // Success callback
        (dataUrl) => {
          if (this.hasImageTarget) {
            this.imageTarget.src = dataUrl
            this.element.classList.remove('loading-failed')
            
            // Indicate image is loaded
            const imgElement = this.imageTarget
            if (imgElement.classList.contains('failed')) {
              imgElement.classList.remove('failed')
            }
          }
          
          timer = this.intervalValue
          this.isFetching = false
          
          if (this.enabledValue && !this.isComplete) {
            this.runImagePreview()
          }
        },
        // Error callback
        () => {
          // Reduce frequency on errors
          timer = this.longPauseValue
          this.isFetching = false
          
          if (this.enabledValue && !this.isComplete) {
            this.runImagePreview()
          }
        }
      )
    }, timer)
  }
  
  // Convert an image URL to a data URL
  loadImageToDataURL(url, successCallback, errorCallback) {
    const xhr = new XMLHttpRequest()
    xhr.onload = function() {
      const reader = new FileReader()
      reader.onloadend = function() {
        successCallback(reader.result)
      }
      reader.readAsDataURL(xhr.response)
    }
    xhr.onerror = function() {
      errorCallback()
    }
    xhr.open('GET', url)
    xhr.responseType = 'blob'
    xhr.send()
  }
  
  // Force reload the image with a new timestamp
  reloadImage() {
    if (!this.hasImageTarget) return
    
    const img = this.imageTarget
    const currentSrc = img.src
    
    if (currentSrc) {
      const timestamp = Date.now()
      const newSrc = currentSrc.includes('?') 
        ? currentSrc.replace(/t=\d+/, `t=${timestamp}`)
        : `${currentSrc}?t=${timestamp}`
      
      img.src = newSrc
    }
  }
}