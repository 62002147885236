import { Controller } from "@hotwired/stimulus"

/**
 * Events Index Controller - Bridge between Stimulus and legacy React
 *
 * This controller serves as a bridge between the modern Stimulus-based code
 * and the legacy React components. It can be expanded gradually to replace
 * React functionality piece by piece.
 */
export default class extends Controller {
  static targets = ["container", "filter", "notification"]
  static values = {
    audioEnabled: Boolean,
    viewMode: String
  }

  connect() {
    console.log("Events index controller connected")
    
    // This controller can initialize any non-React UI elements
    // and set up event listeners outside of React
    
    // As React components are replaced, move their functionality here
    this.setupAudioControls()
  }
  
  setupAudioControls() {
    // Example of functionality that could be moved from React to Stimulus
    if (this.hasNotificationTarget && this.hasAudioEnabledValue) {
      this.notificationSound = new Audio(window.pingSoundPath)
    }
  }
  
  // Actions that can be called from the UI
  toggleAudio() {
    this.audioEnabledValue = !this.audioEnabledValue
    
    if (this.audioEnabledValue) {
      this.notificationSound?.play().catch(e => console.error("Error playing sound:", e))
    }
    
    // If React is still handling this, dispatch a custom event for React to listen to
    this.dispatch("audioToggled", { detail: { enabled: this.audioEnabledValue } })
  }
  
  // For new features, implement them directly in Stimulus
  resetFilters(event) {
    if (this.hasFilterTarget) {
      this.filterTarget.reset()
      // Trigger filter submission
      this.filterTarget.dispatchEvent(new Event("submit"))
    }
  }
}