import { Controller } from "@hotwired/stimulus"

// Controls the live preview toggle switch
// <div data-controller="live-preview-toggle" data-live-preview-toggle-enabled-value="true">
//   <button data-action="click->live-preview-toggle#toggle" data-live-preview-toggle-target="button">
//     Toggle Live Preview
//   </button>
// </div>
export default class extends Controller {
  static targets = ["button"]
  static values = {
    enabled: { type: Boolean, default: false }
  }
  
  connect() {
    this.updateButtonState()
  }
  
  // When enabled value changes, update button state
  enabledValueChanged() {
    this.updateButtonState()
    
    // Save preference in a cookie
    document.cookie = `live_preview=${this.enabledValue ? 'on' : 'off'};path=/;max-age=31536000`
    
    // Dispatch event for all events to pick up
    document.dispatchEvent(
      new CustomEvent("events:toggleLivePreview", {
        detail: { enabled: this.enabledValue }
      })
    )
  }
  
  // Toggle the live preview state
  toggle() {
    this.enabledValue = !this.enabledValue
  }
  
  // Update button state based on enabled value
  updateButtonState() {
    if (this.hasButtonTarget) {
      if (this.enabledValue) {
        this.buttonTarget.classList.add('active')
        this.buttonTarget.setAttribute('title', 'Live preview enabled - click to disable')
      } else {
        this.buttonTarget.classList.remove('active')
        this.buttonTarget.setAttribute('title', 'Live preview disabled - click to enable')
      }
    }
  }
}