import { Controller } from "@hotwired/stimulus"

// Manages the events view menu (view mode, grid size, scaling, etc.)
export default class extends Controller {
  static targets = [
    "thumbnailsView", "listView", "displayMode", "livePreview", 
    "realtimeUpdates", "gridSize", "scaling", "audio", "resetButton"
  ]
  
  static values = {
    viewMode: { type: String, default: "thumbnails" },
    displayMode: { type: String, default: "open" },
    gridSize: { type: Number, default: 4 },
    scaling: { type: String, default: "fit" },
    livePreview: { type: Boolean, default: true },
    realtimeUpdates: { type: Boolean, default: true },
    audioEnabled: { type: Boolean, default: false }
  }
  
  connect() {
    // Load saved preferences from cookies
    this.loadPreferences()
    
    // Calculate max grid size based on screen width
    this.calculateMaxGridSize()
    
    // Initialize the UI based on values
    this.updateUI()
    
    // Set initial slider position
    this.updateSliderPosition()
    
    // Apply grid size directly to ensure it's correctly set on page load
    this.applyGridSizeToContainer()
    
    // Initialize Select2 for dropdown
    this.initializeSelect2()
    
    // Set the primary color CSS variable if not already set
    const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-color') || '#337ab7';
    document.documentElement.style.setProperty('--primary-color', primaryColor);
    
    // Also set an RGB version for transparency
    const rgbMatch = primaryColor.match(/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i);
    if (rgbMatch) {
      const r = parseInt(rgbMatch[1], 16);
      const g = parseInt(rgbMatch[2], 16);
      const b = parseInt(rgbMatch[3], 16);
      document.documentElement.style.setProperty('--primary-color-rgb', `${r}, ${g}, ${b}`);
    } else {
      // Fallback to default blue
      document.documentElement.style.setProperty('--primary-color-rgb', '51, 122, 183');
    }
    
    // Initialize tooltips
    this.initializeTooltips();
    
    // Setup slider drag handlers
    this.setupSliderDragHandlers();
    
    // Listen for window resize events
    this.boundResizeHandler = this.handleResize.bind(this)
    window.addEventListener('resize', this.boundResizeHandler)
    
    // Wait for container to be fully rendered before recalculating
    setTimeout(() => {
      this.calculateMaxGridSize()
      this.applyGridSizeToContainer()
    }, 100)
  }
  
  calculateMaxGridSize() {
    // Try multiple approaches to get the most accurate container width
    let containerWidth = 0;
    const eventsContainer = document.querySelector('.events-container');
    const thumbnailsContainer = document.getElementById('events-thumbnails-container');
    
    // First try to get the width from the main events container (most reliable)
    if (eventsContainer && eventsContainer.offsetWidth > 200) {
      containerWidth = eventsContainer.offsetWidth;
    }
    // If that fails, try the thumbnails container
    else if (thumbnailsContainer && thumbnailsContainer.offsetWidth > 200) {
      containerWidth = thumbnailsContainer.offsetWidth;
    }
    // If both fail, use the window width minus some padding for sidebars
    else {
      containerWidth = window.innerWidth * 0.8; // 80% of window width as an estimate
    }
    
    // Calculate reasonable max grid size based on container width
    // Minimum thumbnail width should be about 180px for good visibility
    const dynamicGridSize = Math.max(1, Math.floor(containerWidth / 180));
    
    // Limit to maximum of 6 columns
    this.maxGridSize = Math.min(dynamicGridSize, 6);
    
    // Log values for debugging
    console.log(`Container width: ${containerWidth}px, Dynamic max grid size: ${this.maxGridSize}`);
    
    // Update the max attribute on the range input
    if (this.hasGridSizeTarget) {
      this.gridSizeTarget.max = this.maxGridSize;
    }
    
    // If the container width seems too small, retry after a short delay
    // This helps when the container isn't fully rendered yet
    if (containerWidth < 500 && !this.widthRetried) {
      this.widthRetried = true;
      setTimeout(() => {
        this.calculateMaxGridSize();
        this.widthRetried = false;
      }, 500);
    }
  }
  
  setupSliderDragHandlers() {
    // Add global event listeners for mouse/touch move and end events
    this.boundHandleDrag = this.handleDrag.bind(this)
    this.boundEndDragging = this.endDragging.bind(this)
    
    // These are added to the document so dragging can continue outside the slider
    document.addEventListener('mousemove', this.boundHandleDrag)
    document.addEventListener('touchmove', this.boundHandleDrag)
    document.addEventListener('mouseup', this.boundEndDragging)
    document.addEventListener('touchend', this.boundEndDragging)
  }
  
  initializeTooltips() {
    // Initialize Bootstrap tooltips if jQuery is available
    if (typeof $ !== 'undefined' && typeof $.fn.tooltip !== 'undefined') {
      try {
        $('[data-toggle="tooltip"]').tooltip();
      } catch (error) {
        console.warn('Tooltip initialization failed:', error);
      }
    }
  }
  
  initializeSelect2() {
    if (this.hasDisplayModeTarget) {
      try {
        // Check if jQuery and Select2 are available
        if (typeof $ !== 'undefined' && typeof $.fn.select2 !== 'undefined') {
          try {
            // Use a simplified configuration without potentially problematic options
            $(this.displayModeTarget).select2({
              minimumResultsForSearch: Infinity, // Hide the search box
              width: '100%'
            }).on('select2:select', (e) => {
              // Manually trigger change event for Stimulus to catch
              this.displayModeTarget.dispatchEvent(new Event('change', { bubbles: true }));
            });
            
            // Fix any container width issues
            setTimeout(() => {
              const select2Container = $(this.displayModeTarget).next('.select2-container');
              if (select2Container.length) {
                select2Container.css('width', '100%');
              }
            }, 100);
          } catch (select2Error) {
            // Fallback to native select if Select2 initialization fails
            console.warn('Select2 initialization failed, using native select:', select2Error);
            // Make sure the native select control is still usable
            this.displayModeTarget.style.display = 'block';
            this.displayModeTarget.style.width = '100%';
          }
        } else {
          // If Select2 is not available at all, ensure native select is visible
          this.displayModeTarget.style.display = 'block';
          this.displayModeTarget.style.width = '100%';
        }
      } catch (error) {
        console.warn('Select2 setup failed:', error);
        // Make sure the native select is usable as fallback
        if (this.displayModeTarget) {
          this.displayModeTarget.style.display = 'block';
          this.displayModeTarget.style.width = '100%';
        }
      }
    }
  }
  
  formatSelect2Option(option) {
    try {
      if (!option || !option.id) return option.text || '';
      
      if ($ && option.element) {
        try {
          const iconClass = $(option.element).data('icon-class');
          if (iconClass) {
            return $(`<span><i class="${iconClass} mr-2" aria-hidden="true"></i>${option.text}</span>`);
          }
        } catch (error) {
          console.warn('Error formatting Select2 option:', error);
        }
      }
      
      return option.text || '';
    } catch (error) {
      console.warn('Fatal error in formatSelect2Option:', error);
      return '';
    }
  }
  
  updateSliderPosition() {
    const sliderElement = document.querySelector('.slider-horizontal')
    if (sliderElement && this.gridSizeValue) {
      const maxIdx = this.maxGridSize - 1
      const percentage = ((this.gridSizeValue - 1) / maxIdx) * 100
      sliderElement.style.setProperty('--slider-value', `${percentage}%`)
    }
  }
  
  disconnect() {
    // Clean up event listeners
    window.removeEventListener('resize', this.boundResizeHandler)
    
    // Remove slider drag handlers
    document.removeEventListener('mousemove', this.boundHandleDrag)
    document.removeEventListener('touchmove', this.boundHandleDrag)
    document.removeEventListener('mouseup', this.boundEndDragging)
    document.removeEventListener('touchend', this.boundEndDragging)
    
    // Cleanup tooltips
    if (typeof $ !== 'undefined') {
      try {
        if (typeof $.fn.tooltip !== 'undefined') {
          $('[data-toggle="tooltip"]').tooltip('dispose');
        }
      } catch (error) {
        console.warn('Tooltip disposal failed:', error);
      }
    }
    
    // Cleanup Select2
    if (typeof $ !== 'undefined' && this.hasDisplayModeTarget) {
      try {
        if (typeof $.fn.select2 !== 'undefined') {
          $(this.displayModeTarget).select2('destroy');
        }
      } catch (error) {
        console.warn('Select2 disposal failed:', error);
      }
    }
  }
  
  // Values changed callbacks
  viewModeValueChanged() {
    this.updateViewMode()
    this.saveCookie('events_view_mode', this.viewModeValue)
    this.dispatchViewModeChanged()
  }
  
  displayModeValueChanged() {
    this.saveCookie('events_display_mode', this.displayModeValue)
    // Reload events with the new display mode
    this.fetchEvents(true)
  }
  
  gridSizeValueChanged() {
    this.updateGridSize()
    this.saveCookie('events_grid_size', this.gridSizeValue)
    this.dispatchGridSizeChanged()
  }
  
  scalingValueChanged() {
    this.updateScaling()
    this.saveCookie('events_scaling', this.scalingValue)
    this.dispatchScalingChanged()
  }
  
  livePreviewValueChanged() {
    // Only update UI and save cookie, don't dispatch an event here 
    // to avoid potential loops with events_controller
    this.updateLivePreview()
    this.saveCookie('events_live_preview', this.livePreviewValue ? 'on' : 'off')
    
    // Only dispatch the event when an explicit UI action changes the value
    // NOT when the value is changed programmatically
    // We'll handle that in the toggleLivePreview method instead
  }
  
  realtimeUpdatesValueChanged() {
    this.updateRealtimeUpdates()
    this.saveCookie('events_realtime_updates', this.realtimeUpdatesValue ? 'on' : 'off')
    this.dispatchRealtimeUpdatesChanged()
  }
  
  audioEnabledValueChanged() {
    this.updateAudio()
    this.saveCookie('events_audio_enabled', this.audioEnabledValue ? 'on' : 'off')
  }
  
  // UI actions
  toggleViewMode(event) {
    const mode = event.currentTarget.dataset.mode
    this.viewModeValue = mode
  }
  
  changeDisplayMode(event) {
    const select = event.currentTarget
    this.displayModeValue = select.value
  }
  
  toggleScaling(event) {
    event.preventDefault()
    this.scalingValue = this.scalingValue === 'fit' ? 'fill' : 'fit'
  }
  
  toggleLivePreview(event) {
    // Toggle the value based on who triggered the event
    if (event.target.tagName === 'INPUT') {
      // Direct checkbox change
      this.livePreviewValue = this.livePreviewTarget.checked
    } else {
      // Label click or other trigger - invert current value
      this.livePreviewValue = !this.livePreviewValue
      
      // Directly update the checkbox
      if (this.hasLivePreviewTarget) {
        this.livePreviewTarget.checked = this.livePreviewValue
      }
    }
    
    // This is a user action, so dispatch the event to notify other controllers
    this.dispatchLivePreviewChanged()
  }
  
  toggleRealtimeUpdates(event) {
    // Toggle the value based on who triggered the event
    if (event.target.tagName === 'INPUT') {
      // Direct checkbox change
      this.realtimeUpdatesValue = this.realtimeUpdatesTarget.checked
    } else {
      // Label click or other trigger - invert current value
      this.realtimeUpdatesValue = !this.realtimeUpdatesValue
      
      // Directly update the checkbox
      if (this.hasRealtimeUpdatesTarget) {
        this.realtimeUpdatesTarget.checked = this.realtimeUpdatesValue
      }
    }
  }
  
  toggleAudio(event) {
    this.audioEnabledValue = !this.audioEnabledValue
    
    // Directly update the UI
    if (this.hasAudioTarget) {
      this.audioTarget.className = `fas fa-volume-${this.audioEnabledValue ? 'up' : 'mute'}`
      this.audioTarget.title = this.audioEnabledValue ? 'Disable audio' : 'Enable audio'
    }
  }
  
  updateGridSize(event) {
    if (event) {
      this.gridSizeValue = parseInt(event.currentTarget.value, 10)
    }
  }
  
  reset() {
    // Reset all values to defaults
    this.viewModeValue = "thumbnails"
    this.displayModeValue = "open"
    this.gridSizeValue = 4
    this.scalingValue = "fit"
    this.livePreviewValue = true
    this.realtimeUpdatesValue = true
    
    // Force fetch new events with reset filters
    this.fetchEvents(true)
  }
  
  expandEvents() {
    const form = document.querySelector('#events_filter form')
    if (form) {
      const params = new URLSearchParams(new FormData(form))
      const recordingsParams = new URLSearchParams()
      
      // Convert events params to recordings params
      for (const [key, value] of params.entries()) {
        const newKey = key.replace('events', 'recordings')
        recordingsParams.append(newKey, value)
      }
      
      window.location.href = `/recordings?${recordingsParams.toString()}`
    }
  }
  
  // Helper methods
  updateUI() {
    this.updateViewMode()
    this.updateGridSize()
    this.updateScaling()
    this.updateLivePreview()
    this.updateRealtimeUpdates()
    this.updateAudio()
  }
  
  updateViewMode() {
    if (this.hasThumbnailsViewTarget && this.hasListViewTarget) {
      // Update class for thumbnail view icon
      this.thumbnailsViewTarget.classList.toggle('primary-colour', this.viewModeValue === 'thumbnails')
      
      // Update class for list view icon
      this.listViewTarget.classList.toggle('primary-colour', this.viewModeValue === 'list')
    }
    
    // Toggle visibility of the appropriate container
    const thumbnailsContainer = document.getElementById('events-thumbnails-container')
    const listContainer = document.getElementById('events-list-container')
    
    if (thumbnailsContainer && listContainer) {
      thumbnailsContainer.classList.toggle('d-none', this.viewModeValue !== 'thumbnails')
      listContainer.classList.toggle('d-none', this.viewModeValue !== 'list')
    }
  }
  
  startDragging(event) {
    // Store the slider element that's being dragged
    this.activeSlider = event.currentTarget
    this.isDragging = true
    
    // Prevent default to avoid text selection during drag
    event.preventDefault()
    
    // Handle the initial position update
    this.handleDrag(event)
  }
  
  handleDrag(event) {
    // Only process if we're dragging
    if (!this.isDragging || !this.activeSlider) return
    
    // Get the client X position from either mouse or touch event
    let clientX
    if (event.type === 'touchmove') {
      clientX = event.touches[0].clientX
    } else {
      clientX = event.clientX
    }
    
    // Calculate position as percentage
    const rect = this.activeSlider.getBoundingClientRect()
    const offsetX = Math.max(0, Math.min(rect.width, clientX - rect.left))
    
    // Use discrete steps (hard stops) for slider values
    const sliderWidth = rect.width
    const maxIdx = this.maxGridSize - 1 // e.g., 5 for maxGridSize of 6
    
    // Determine which segment of the slider we're in
    let nearestValue = 1
    
    // For each possible position (1 to maxGridSize)
    // Find which stop is closest to current mouse position
    let minDistance = Infinity
    for (let i = 1; i <= this.maxGridSize; i++) {
      // Calculate exact position for each stop
      // For 6 steps, positions would be at 0%, 20%, 40%, 60%, 80%, 100%
      const stopPosition = (i - 1) / maxIdx * sliderWidth
      
      // Find distance to this stop
      const distance = Math.abs(offsetX - stopPosition)
      
      // If this is closer than previous best match, update
      if (distance < minDistance) {
        minDistance = distance
        nearestValue = i
      }
    }
    
    // Calculate the slider percentage for the nearest stop
    const sliderPercentage = ((nearestValue - 1) / maxIdx) * 100
    
    // Position slider handle at exactly the stop point (no interpolation)
    this.activeSlider.style.setProperty('--slider-value', `${sliderPercentage}%`)
    
    // Only update if value changed
    if (nearestValue !== this.gridSizeValue) {
      // Update the value
      this.gridSizeValue = nearestValue
      
      // Update label in real-time
      const gridLabel = document.getElementById('grid-label')
      if (gridLabel) {
        gridLabel.textContent = this.gridSizeValue
      }
      
      // Update grid size on event container
      this.applyGridSizeToContainer()
    }
    
    // Prevent default to avoid text selection during drag
    event.preventDefault()
  }
  
  endDragging(event) {
    if (this.isDragging) {
      // Save the final value to cookie
      this.saveCookie('events_grid_size', this.gridSizeValue)
      
      // Dispatch the grid size changed event
      this.dispatchGridSizeChanged()
      
      // Reset dragging state
      this.isDragging = false
      this.activeSlider = null
    }
  }
  
  handleSliderClick(event) {
    // Get the slider element
    const sliderElement = event.currentTarget
    
    // Calculate position
    const rect = sliderElement.getBoundingClientRect()
    const offsetX = Math.max(0, Math.min(rect.width, event.clientX - rect.left))
    
    // Use the same hard stop logic as in handleDrag
    const sliderWidth = rect.width
    const maxIdx = this.maxGridSize - 1 // e.g., 5 for maxGridSize of 6
    
    // Find which stop is closest to the click position
    let nearestValue = 1
    let minDistance = Infinity
    
    // For each possible position (1 to maxGridSize)
    for (let i = 1; i <= this.maxGridSize; i++) {
      // Calculate exact position for each stop
      const stopPosition = (i - 1) / maxIdx * sliderWidth
      
      // Find distance to this stop
      const distance = Math.abs(offsetX - stopPosition)
      
      // If this is closer than previous best match, update
      if (distance < minDistance) {
        minDistance = distance
        nearestValue = i
      }
    }
    
    // Calculate the slider percentage for the nearest stop
    const sliderPercentage = ((nearestValue - 1) / maxIdx) * 100
    
    // Position slider handle exactly at the stop point
    sliderElement.style.setProperty('--slider-value', `${sliderPercentage}%`)
    
    // Update the grid size value
    this.gridSizeValue = nearestValue
    
    // Update label in UI
    const gridLabel = document.getElementById('grid-label')
    if (gridLabel) {
      gridLabel.textContent = this.gridSizeValue
    }
    
    // Apply the new grid size to the container
    this.applyGridSizeToContainer()
  }
  
  updateGridSize(event) {
    if (event) {
      // Set grid size value from event, ensuring it's within range
      const newValue = parseInt(event.currentTarget.value, 10)
      this.gridSizeValue = Math.max(1, Math.min(this.maxGridSize, newValue))
    }
    
    if (this.hasGridSizeTarget) {
      // Update the input element value
      this.gridSizeTarget.value = this.gridSizeValue
      
      // Also update the grid label if it exists
      const gridLabel = document.getElementById('grid-label')
      if (gridLabel) {
        gridLabel.textContent = this.gridSizeValue
      }
      
      // Update the slider position with CSS variable
      const sliderElements = document.querySelectorAll('.slider-horizontal')
      if (sliderElements.length) {
        const maxIdx = Math.max(1, this.maxGridSize - 1) // Prevent division by zero
        const percentage = ((this.gridSizeValue - 1) / maxIdx) * 100
        sliderElements.forEach(sliderElement => {
          sliderElement.style.setProperty('--slider-value', `${percentage}%`)
        })
      }
      
      // Update grid classes on the container
      this.applyGridSizeToContainer()
    }
  }
  
  // Apply grid size to container (extracted to separate method for reuse)
  applyGridSizeToContainer() {
    const container = document.getElementById('events-thumbnails-container')
    if (container) {
      // Remove existing grid classes for all possible sizes (up to a reasonable max)
      for (let i = 1; i <= 12; i++) {
        container.classList.remove(`grid-${i}`)
      }
      
      // Add the new grid class
      container.classList.add(`grid-${this.gridSizeValue}`)
      
      // Also update custom CSS variable for more precise sizing
      const thumbnailWidth = `${100 / this.gridSizeValue}%`
      container.style.setProperty('--thumbnail-width', thumbnailWidth)
      
      // Force reflow to apply new grid size immediately
      container.style.display = 'none'
      container.offsetHeight // Force reflow
      container.style.display = ''
    }
  }
  
  updateScaling() {
    if (this.hasScalingTarget) {
      const icon = this.scalingTarget.querySelector('i')
      if (icon) {
        icon.className = `fa ${this.scalingValue === 'fit' ? 'fa-expand-alt' : 'fa-compress-alt'}`
      }
      
      // Update scaling classes on event thumbnails
      document.querySelectorAll('.xanthumb-card').forEach(card => {
        card.classList.toggle('scaling-fit', this.scalingValue === 'fit')
        card.classList.toggle('scaling-fill', this.scalingValue === 'fill')
      })
    }
  }
  
  updateLivePreview() {
    if (this.hasLivePreviewTarget) {
      // The target is now directly the checkbox
      this.livePreviewTarget.checked = this.livePreviewValue
    }
  }
  
  updateRealtimeUpdates() {
    if (this.hasRealtimeUpdatesTarget) {
      // The target is now directly the checkbox
      this.realtimeUpdatesTarget.checked = this.realtimeUpdatesValue
    }
  }
  
  updateAudio() {
    if (this.hasAudioTarget) {
      // The audio target is the icon directly
      this.audioTarget.className = `fas fa-volume-${this.audioEnabledValue ? 'up' : 'mute'}`
      this.audioTarget.title = this.audioEnabledValue ? 'Disable audio' : 'Enable audio'
    }
  }
  
  loadPreferences() {
    // Load values from cookies or use defaults
    const viewMode = this.getCookie('events_view_mode') || 'thumbnails'
    const displayMode = this.getCookie('events_display_mode') || 'open'
    const gridSize = parseInt(this.getCookie('events_grid_size') || '4', 10)
    const scaling = this.getCookie('events_scaling') || 'fit'
    const livePreview = this.getCookie('events_live_preview') !== 'off'
    const realtimeUpdates = this.getCookie('events_realtime_updates') !== 'off'
    const audioEnabled = this.getCookie('events_audio_enabled') === 'on'
    
    // Set values (will trigger changed callbacks)
    this.viewModeValue = viewMode
    this.displayModeValue = displayMode
    this.gridSizeValue = gridSize
    this.scalingValue = scaling
    this.livePreviewValue = livePreview
    this.realtimeUpdatesValue = realtimeUpdates
    this.audioEnabledValue = audioEnabled
  }
  
  saveCookie(name, value) {
    document.cookie = `${name}=${value};path=/;max-age=31536000`
  }
  
  getCookie(name) {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop().split(';').shift()
    return null
  }
  
  handleResize() {
    // Update UI for mobile vs desktop
    this.isMobile = window.outerWidth <= 576
    
    // Debounce the resize handler to improve performance
    clearTimeout(this.resizeTimer)
    this.resizeTimer = setTimeout(() => {
      // Recalculate max grid size when window size changes
      const previousMax = this.maxGridSize
      const previousGridSize = this.gridSizeValue
      
      // Recalculate based on new container width
      this.calculateMaxGridSize()
      
      // If max or size changed, update UI
      if (previousMax !== this.maxGridSize || previousGridSize !== this.gridSizeValue) {
        // Adjust grid size if needed
        if (this.gridSizeValue > this.maxGridSize) {
          this.gridSizeValue = this.maxGridSize
        }
        
        // Update slider position to reflect any changes
        this.updateSliderPosition()
        
        // Apply the new grid size to the container
        this.applyGridSizeToContainer()
        
        // Update grid label
        const gridLabel = document.getElementById('grid-label')
        if (gridLabel) {
          gridLabel.textContent = this.gridSizeValue
        }
      }
    }, 250) // Wait 250ms after resize finishes to reduce performance impact
  }
  
  fetchEvents(isFilterSubmit = false) {
    // Dispatch custom event to signal that events should be fetched with the current filter
    document.dispatchEvent(
      new CustomEvent("events:fetchEvents", {
        detail: { isFilterSubmit }
      })
    )
  }
  
  // Custom event dispatchers
  dispatchViewModeChanged() {
    document.dispatchEvent(
      new CustomEvent("events:viewModeChanged", {
        detail: { viewMode: this.viewModeValue }
      })
    )
  }
  
  dispatchGridSizeChanged() {
    document.dispatchEvent(
      new CustomEvent("events:gridSizeChanged", {
        detail: { gridSize: this.gridSizeValue }
      })
    )
  }
  
  dispatchScalingChanged() {
    document.dispatchEvent(
      new CustomEvent("events:scalingChanged", {
        detail: { scaling: this.scalingValue }
      })
    )
  }
  
  dispatchLivePreviewChanged() {
    document.dispatchEvent(
      new CustomEvent("events:livePreviewChanged", {
        detail: { enabled: this.livePreviewValue }
      })
    )
  }
  
  dispatchRealtimeUpdatesChanged() {
    document.dispatchEvent(
      new CustomEvent("events:realtimeUpdatesChanged", {
        detail: { enabled: this.realtimeUpdatesValue }
      })
    )
  }
}